import { useCallback, useEffect, useState } from "react";
import { getSupabaseAccessToken, logout } from '../api/supabaseClient';

interface AudioRecorderProps {
  wsUrl: string;
  onStart: () => void;
  onStop: () => void;
  onTranscription: (transcription: string) => void;
}

export class AudioRecorder {
  private wsUrl: string;
  private ws?: WebSocket;
  private mediaRecorder?: MediaRecorder;
  private onStart: () => void;
  private onStop: () => void;
  private onTranscription: (transcription: string) => void;

  constructor({ wsUrl, onStart, onStop, onTranscription }: AudioRecorderProps) {
    this.wsUrl = wsUrl;
    this.onStart = onStart;
    this.onStop = onStop;
    this.onTranscription = onTranscription;
  }

  setupWebSocket(): void {
    if (!this.ws) return;

    this.ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    this.ws.onmessage = (event: MessageEvent) => {
      const response = JSON.parse(event.data);
      this.onTranscription(response.transcription);
    };

    this.ws.onclose = () => {
      console.log("WebSocket connection closed");
      this.onStop();
    };
  }

  connectWebSocket(
    sessionId: string,
    userId: string,
    token: string,
    language = "es",
    template: string = "",
    specialityId: string = "general"
  ): void {
    // Construct the query string
    const queryParams = new URLSearchParams({
      session_id: sessionId,
      user_id: userId,
      token,
      language,
      template_id: template,
      speciality_id: specialityId
    }).toString();

    // Append the query string to the WebSocket URL
    const fullWsUrl = `${this.wsUrl}?${queryParams}`;

    // Establish the WebSocket connection with the parameters
    this.ws = new WebSocket(fullWsUrl);
    try {
      this.setupWebSocket();
    } catch (error) {
      console.error("Error setting up WebSocket:", error);
      this.onStop();
    }
  }

  isWebSocketConnected(): boolean {
    return this.ws !== undefined && this.ws.readyState === WebSocket.OPEN;
  }

  closeWebSocket(): void {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close();
    }
  }

  async startRecording(stream: MediaStream): Promise<void> {
    if (typeof MediaRecorder === "undefined") {
      console.error("MediaRecorder is not supported by this browser.");
      return;
    }
    this.onStart();
    this.mediaRecorder = new MediaRecorder(stream);
    this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
      if (event.data.size > 0 && this.isWebSocketConnected()) {
        this.ws!.send(event.data);
      }
    };
    this.mediaRecorder.start(3000);
  }

  stopRecording(): void {
    if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
      this.onStop();
      this.mediaRecorder.stop();
    } else {
      console.log("Cannot stop recording: MediaRecorder is not recording.");
    }
  }
}

// Custom hook for managing the audio recorder
export function useAudioRecorder(
  wsUrl: string,
  transcriptionLanguage: string,
  templateSelected: string,
  handleStart: () => void = () => {},
  handleStop: () => void = () => {}
) {
  // Using useCallback to ensure these functions don't cause the effect to rerun unnecessarily
  const stableHandleStart = useCallback(handleStart, []);
  const stableHandleStop = useCallback(handleStop, []);

  const [isRecording, setIsRecording] = useState(false);
  const [audioRecorder, setAudioRecorder] = useState<AudioRecorder | null>(
    null
  );
  const [wsSessionTranscription, setWsSessionTranscription] = useState("");

  useEffect(() => {
    const recorder = new AudioRecorder({
      wsUrl,
      onStart: () => {
        setIsRecording(true);
        stableHandleStart();
      },
      onStop: () => {
        setIsRecording(false);
        stableHandleStop();
      },
      onTranscription: (newTranscription) => {
        setWsSessionTranscription(newTranscription);
      },
    });

    setAudioRecorder(recorder);

    return () => {
      recorder.closeWebSocket();
    };
  }, [wsUrl, stableHandleStart, stableHandleStop]);

  useEffect(() => {
    console.log(
      "Transcription in useAudioRecorder updated:",
      wsSessionTranscription
    );
  }, [wsSessionTranscription]);

  useEffect(() => {
    if (audioRecorder && !audioRecorder.isWebSocketConnected()) {
      setIsRecording(false);
    }
  }, [audioRecorder]);

  const startRecording = async (
    sessionId: string = "default",
    userId: string = "default",
    specialityId: string = "general",
    language: string = transcriptionLanguage,
    template: string = templateSelected,
  ): Promise<void> => {
    if (!audioRecorder) return;


    const token = await getSupabaseAccessToken() || '';
    
    // Connect WebSocket with parameters
    if (!audioRecorder.isWebSocketConnected()) {
      audioRecorder.connectWebSocket(sessionId, userId, token, language, template, specialityId);
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioRecorder.startRecording(stream);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = (): void => {
    audioRecorder?.stopRecording();
    audioRecorder?.closeWebSocket();
    setWsSessionTranscription("");
  };

  return {
    wsSessionTranscription,
    setWsSessionTranscription,
    isRecording,
    startRecording,
    stopRecording,
  };
}
