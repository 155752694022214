import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "./styles/NoteTemplate.css";
import SnackbarNotification from "../Navigation/Tools/SnackbarNotification";
import { Section } from "@/types";

interface NoteTemplateProps {
  sections: Section[];
  saveNote: (sections: Section[]) => void;
}

const NoteTemplate: React.FC<NoteTemplateProps> = ({ sections, saveNote }) => {
  const [hoveredSection, setHoveredSection] = useState<number | null>(null);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    adjustTextareaHeightOnMount();
    window.addEventListener('resize', adjustAllTextareaHeights);

    return () => {
      window.removeEventListener('resize', adjustAllTextareaHeights);
    };
  }, []);

  useEffect(() => {
    adjustTextareaHeightOnMount();
  }, [sections]);

  const adjustTextareaHeightOnMount = () => {
    const textareas = document.querySelectorAll('.editable-content');
    textareas.forEach(textarea => adjustTextareaHeight({ target: textarea } as React.ChangeEvent<HTMLTextAreaElement>));
  };

  const adjustAllTextareaHeights = () => {
    const textareas = document.querySelectorAll('.editable-content');
    textareas.forEach(textarea => {
      adjustTextareaHeight({ target: textarea } as React.ChangeEvent<HTMLTextAreaElement>);
    });
  };

  const handleTextChange = (index: number, text: string) => {
    const newSections = [...sections];
    console.log("Note before update:", newSections);
    newSections[index].content = text;
    saveNote(newSections);
    console.log("Note updated:", newSections);
  };

  const adjustTextareaHeight = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = "inherit"; // Reset the height
    e.target.style.height = `${e.target.scrollHeight + 1}px`; // Set the height to scroll height
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopiedToClipboard(true);
        console.log("Text copied to clipboard: ", text);
        setTimeout(() => {
          setCopiedToClipboard(false);
        }, 200);
      },
      (err) => {
        console.error("Unable to copy text: ", err);
      }
    );
  };

  if (!sections || sections.length === 0)
    return <p className="no-data">{t('no_data_available')}</p>;

  return (
    <div className="note-container">
      <SnackbarNotification triggerOpen={copiedToClipboard} message={t('copied_to_clipboard')} />
      {sections.map((section, index) => (
        <div
          key={index}
          className="section"
          onMouseEnter={() => setHoveredSection(index)}
          onMouseLeave={() => setHoveredSection(null)}
        >
          <div className="section-header">
            <h3 className="section-title">{section.title}</h3>
            <button
              className="copy-button"
              onClick={() => copyToClipboard(section.content)}
              aria-label={t('copy_section_text')}
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </div>

          <div className="section-content">
            <textarea
              className="editable-content"
              value={section.content || ""}
              placeholder={t('empty_section')}
              onChange={(e) => handleTextChange(index, e.target.value)}
              onInput={(e) => adjustTextareaHeight(e as React.ChangeEvent<HTMLTextAreaElement>)}
              style={{ overflow: "hidden" }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default NoteTemplate;
